import './bootstrap';
import '../css/app.css';

import { createRoot, hydrateRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';


const appName = import.meta.env.VITE_APP_NAME;

import { Ziggy } from './ziggy.js';
import route from 'ziggy-js';

createInertiaApp({
    title: (title) => `${title} | ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({ el, App, props }) {
        globalThis.Ziggy = Ziggy;
        globalThis.route = route;

        window.route = route;
        window.Ziggy = Ziggy;

        // process.env.NODE_ENV !== 'production' ? createRoot(el).render(<App {...props} />) : hydrateRoot(el, <App {...props} />)
        
        createRoot(el).render(<App {...props} />)
        
        delete el.dataset.page;
    },
    progress: {
        color: '#393ABF',
    },
})